@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
	font-family: 'Open Sans Regular';
	src: url('./fonts/OpenSans-Regular.eot') format('eot'),
		url('./fonts/OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

* {
	font-family: 'Open Sans Regular';
}

html,
body,
#root {
	height: 100%;
	width: 100%;
}

body {
	@apply bg-background text-on-background;
}

.App {
	@apply w-full h-full;
}

.auth-container {
	min-height: 450px;
}

.Toastify__toast-container--top-right {
	top: 4em;
}
.Toastify__toast--success {
	@apply bg-success;
}

.min-h-10 {
	min-height: 40px;
}
